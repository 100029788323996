import React from "react"
import { Provider } from "react-redux"
import { AnimatePresence } from "framer-motion"

import "./src/styles/global.css"

import "@fontsource/montserrat"; // Defaults to weight 400.

import createStore from "./src/state/createStore"

export function wrapPageElement({ element }) {
  const store = createStore();
  return (
    <Provider store={store}>
      <AnimatePresence exitBeforeEnter>
        
        {element}
      </AnimatePresence>
    </Provider>)
}

